import RoomService from "@/services/room.service";

export const room = {
	namespaced: true,
	state: {
		activeRooms: [],
		bookingRooms: [],
		fileStatus: {},
		rooms: [],
		statuses: [],
		counter: [],
		currentRoomsPage: null,
	},
	actions: {
		getFiles({ state }) {
			return RoomService.getFiles().then((response) => {
				state.activeRooms.forEach((r) => {
					const stateRoom = response.data.find((f) => f.roomId === r.roomId);

					if (stateRoom) {
						r.files = stateRoom.files;
					}
				});
			});
		},

		deleteFile({ state }, payload) {
			return RoomService.deleteFile(payload).then(() => {
				const stateRoom = state.activeRooms.find((r) => r.roomId === payload.roomId);

				if (stateRoom) {
					stateRoom.files = stateRoom.files.filter((f) => f.url !== payload.url);
				}
			});
		},

		updateFiles({ state }, payload) {
			const stateRoom = state.activeRooms.find((r) => r.roomId === payload.roomId);

			if (stateRoom) {
				stateRoom.files = payload.files;
			}
		},

		getActiveRooms({ state }) {
			return RoomService.getActiveRooms().then((response) => {
				state.activeRooms = response.data;
			});
		},

		getBookingRooms({ state }) {
			return RoomService.getBookingRooms().then((response) => {
				state.bookingRooms = response.data;
			});
		},

		getRooms({ state }, obj = null) {
			return RoomService.getRooms(obj?.url, obj?.filter).then((response) => {
				state.rooms = response.data;
			});
		},

		getStatuses({ state }, url = null) {
			return RoomService.getStatuses(url).then((response) => {
				state.statuses = response.data;
			});
		},

		deleteRoom({ state }, roomId) {
			return RoomService.deleteRoom(roomId).then((response) => {
				state.rooms = response.data;
			});
		},

		deleteRooms({ state }, selectedIds) {
			return RoomService.deleteRooms(selectedIds).then((response) => {
				state.rooms = response.data;
			});
		},

		getRoom({ state }, roomId) {
			return RoomService.getRoom(roomId).then((response) => {
				state.room = response.data;
			});
		},

		getFileStatus({ state }, roomId) {
			return RoomService.getFileStatus(roomId).then((response) => {
				state.fileStatus = response.data;
			});
		},

		checkCounter({ state }) {
			return RoomService.checkCounter().then((response) => {
				state.counter = response.data;
			});
		},

		updateRoom({ state }, payload) {
			payload.forEach((room) => {
				const stateRoom = state.activeRooms.find((r) => r.roomId === room.roomId);

				if (stateRoom) {
					stateRoom.peers = room.peers;
				} else {
					state.activeRooms.push(room);
				}
			});
		},

		removeRoom({ state }, roomId) {
			state.activeRooms = state.activeRooms.filter((r) => r.roomId !== roomId);
		},
		changeCurrentRoomsPage({state}, page) {
			state.currentRoomsPage = page
		},
	},
};
