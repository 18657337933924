import AuthService from "./auth.service";
import axios from "axios";

class RoomService {
	getActiveRooms() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/rooms/active", { headers: AuthService.authHeader() });
	}

	getBookingRooms() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/rooms/reserve", { headers: AuthService.authHeader() });
	}

	getRooms(url, filter) {
		let path = "/rooms";

		if (url) {
			path = url;
		}
		if(filter) {
			path += `?filter=true&statuses_id=${filter}`
		}

		return axios.get(process.env.VUE_APP_SERVER_LINK + path, { headers: AuthService.authHeader() });
	}

	getStatuses(url) {
		let path = "/rooms/statuses";

		if (url) {
			path = url;
		}

		return axios.get(process.env.VUE_APP_SERVER_LINK + path, { headers: AuthService.authHeader() });
	}

	getRoom(roomId) {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/room/${roomId}`, { headers: AuthService.authHeader() });
	}


	getFileStatus(roomId) {
		return axios
			.post(
				process.env.VUE_APP_SERVER_LINK + "/room/file-status",
				{ roomId: roomId },
				{ headers: AuthService.authHeader() }
			)
	}

	checkCounter() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/counter/check`, { headers: AuthService.authHeader() });
	}

	deleteFile(data) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK + `/file/${data.roomId}/${data.type}/${data.file}`, {
			headers: AuthService.authHeader(),
		});
	}

	deleteRoom(roomId) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK + `/room/${roomId}`, {
			headers: AuthService.authHeader(),
		});
	}

	deleteRooms(selectedIds) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK + `/rooms/delete`, {
			headers: AuthService.authHeader(),
			data: {
				ids: selectedIds,
			}
		});
	}

	getFiles() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/files/active", { headers: AuthService.authHeader() });
	}
}

export default new RoomService();
