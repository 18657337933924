<template>
  <nav-bar />
  <aside-menu :menu="menu" />
  <router-view />

  <modal-box
    v-model="modal.opened"
    :title="modal.title || $t('panel.common.confirmTitle')"
    :has-cancel="modal.hasCancel"
    :confirm-label="modal.label || $t('panel.common.confirm')"
    :cancel-label="modal.label || $t('panel.common.cancel')"
    :button="modal.color"
    :on-confirm="modal.onConfirm"
  >
    <p>{{ modal.text }}</p>
  </modal-box>

  <footer-bar />
</template>

<style>
div[class^="v-data-table-footer"] {
  display: none!important;
}
input[id^="input-"] {
  cursor: pointer!important;
  position: inherit!important;
  left: 0!important;
  top: 0!important;
  width: 20px!important;
  height: 20px!important;
  opacity: 1!important;
}
.v-selection-control__input:hover::before {
  opacity: 0!important;
}
.v-ripple__container {
  opacity: 0 !important;
}
</style>
<script>
// @ is an alias to /src
// import { useStore } from 'vuex'
import menu from "@/menu.js";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import ModalBox from '@/components/ModalBox';

export default {
  name: "Home",

  components: {
    FooterBar,
    AsideMenu,
    NavBar,
    ModalBox,
  },

  setup() {
    // const store = useStore()

    // store.commit('user', {
    //   name: 'Livecart admin',
    //   email: 'example@example.com',
    //   avatar: 'https://avatars.dicebear.com/v2/gridy/John-Doe.svg'
    // });
    menu[1] = menu[1].filter(item => !item.hide);

    return {
      menu,
    };
  },

  data() {
    return { socket: undefined };
  },

  computed: {
    modal() {
      return this.$store.state.modal || false;
    },
  },

  mounted() {
    
  },
};
</script>
