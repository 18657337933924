<template>
  <nav
    v-show="isNavBarVisible"
    class="
			top-0
			left-0
			right-0
			fixed
			flex
			bg-white
			h-14
			border-b border-gray-100
			z-30
			w-screen
			transition-all
			xl:pl-60
			lg:w-auto
			lg:items-stretch
		"
    :class="{ 'ml-60': isAsideMobileExpanded }"
  >
    <div class="flex-1 items-stretch flex h-14">
      <nav-bar-item
        type="flex lg:hidden"
        @click.prevent="menuToggleMobile"
      >
        <icon
          :path="menuToggleMobileIcon"
          size="24"
        />
      </nav-bar-item>
      <nav-bar-item
        type="hidden lg:flex xl:hidden"
        @click.prevent="menuOpenLg"
      >
        <icon
          :path="mdiMenu"
          size="24"
        />
      </nav-bar-item>
      <!-- <nav-bar-item>
        <div class="control">
          <input
            type="text"
            class="px-3 focus:ring rounded border-0"
            placeholder="Search ..."
          />
        </div>
      </nav-bar-item> -->
    </div>
    <div class="flex-none items-stretch flex h-14 lg:hidden">
      <nav-bar-item
        class="items-center flex"
        @click.prevent="menuNavBarToggle"
      >
        <icon
          :path="menuNavBarToggleIcon"
          size="24"
        />
      </nav-bar-item>
    </div>
    <div
      class="
				absolute
				w-screen
				top-14
				left-0
				bg-white
				border-b border-gray-100
				shadow
				lg:w-auto
				lg:items-stretch
				lg:flex lg:flex-grow
				lg:static
				lg:border-b-0
				lg:overflow-visible
				lg:shadow-none
			"
      :class="{ hidden: !isMenuNavBarActive, block: isMenuNavBarActive }"
    >
      <div
        class="
					max-h-screen-menu
					overflow-y-auto
					lg:overflow-visible
					lg:flex
					lg:items-stretch
					lg:justify-end
					lg:ml-auto
				"
      >
        <nav-bar-menu has-divider>
          <!-- <user-avatar class="w-6 h-6 mr-3 inline-flex" /> -->
          <div>
            <span>{{ $i18n.locale }}</span>
          </div>

          <template #dropdown>
            <nav-bar-item
              v-for="locale in locales"
              :key="locale"
              :active="locale === $i18n.locale"
              has-divider
              @click="switchLocale(locale)"
            >
              {{ locale }}
            </nav-bar-item>
          </template>
        </nav-bar-menu>
        <nav-bar-item has-divider>
          <div>
            <span>{{ userName }}</span>
          </div>
        </nav-bar-item>
        <nav-bar-item has-divider v-if="!$store.state.user.edit.root">
          <label
              for="toggle_button"
              :class="{'active': userIsActive}"
              class="toggle__button"
          >
              <span
                  v-if="userIsActive"
                  class="toggle__label"
                  style="color: #53B883"
              >Active</span>
            <span
                v-if="!userIsActive"
                class="toggle__label"
                style="color: red"
            >Inactive</span>

            <input
                id="toggle_button"
                v-model="checkedValue"
                type="checkbox"
            >
            <span class="toggle__switch" />
          </label>
        </nav-bar-item>
        <nav-bar-item
          is-desktop-icon-only
          @click="logOut()"
        >
          <nav-bar-item-label
            :icon="mdiLogout"
            label="Log out"
            is-desktop-icon-only
          />
        </nav-bar-item>
      </div>
    </div>
  </nav>
</template>
<style>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.toggle__button input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch {
  display:inline-block;
  height:12px;
  border-radius:6px;
  width:40px;
  background: #BFCBD9;
  box-shadow: inset 0 0 1px #BFCBD9;
  position:relative;
  margin-left: 10px;
  transition: all .25s;
}
.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  transform: translateX(0);
  transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
}
.toggle__button .toggle__switch::after {
  background: #4D4D4D;
  box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
  background: #4D4D4D;
  box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
  opacity:0;
}
.active .toggle__switch {
  background: #adedcb;
  box-shadow: inset 0 0 1px #adedcb;
}
.active .toggle__switch::after,
.active .toggle__switch::before{
  transform:translateX(22px);
}
.active .toggle__switch::after {
  background: #53B883;
  box-shadow: 0 0 1px #53B883;
}
</style>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import {
	mdiForwardburger,
	mdiBackburger,
	mdiClose,
	mdiDotsVertical,
	mdiMenu,
	mdiClockOutline,
	mdiCloud,
	mdiCrop,
	mdiAccount,
	mdiCogOutline,
	mdiEmail,
	mdiLogout,
	mdiGithub,
} from "@mdi/js";
import NavBarItem from "@/components/NavBarItem";
import NavBarMenu from "@/components/NavBarMenu";
import NavBarItemLabel from "@/components/NavBarItemLabel";
import Icon from "@/components/Icon";

export default {
	name: "NavBar",

	components: {
		NavBarItem,
		NavBarMenu,
		NavBarItemLabel,
		Icon,
	},

	setup() {
		const store = useStore();

		const isNavBarVisible = computed(() => !store.state.isFormScreen);

		const isAsideMobileExpanded = computed(
			() => store.state.isAsideMobileExpanded
		);

		const userName = computed(() => store.state.auth.user?.email);

		const menuToggleMobileIcon = computed(() =>
			isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger
		);

		const menuToggleMobile = () => store.dispatch("asideMobileToggle");

		const isMenuNavBarActive = ref(false);

		const menuNavBarToggleIcon = computed(() =>
			isMenuNavBarActive.value ? mdiClose : mdiDotsVertical
		);

		const menuNavBarToggle = () => {
			isMenuNavBarActive.value = !isMenuNavBarActive.value;
		};

		const menuOpenLg = () => {
			store.dispatch("asideLgToggle", true);
		};

		return {
			isNavBarVisible,
			isAsideMobileExpanded,
			userName,
			menuToggleMobileIcon,
			menuToggleMobile,
			isMenuNavBarActive,
			menuNavBarToggleIcon,
			menuNavBarToggle,
			menuOpenLg,
			mdiMenu,
			mdiClockOutline,
			mdiCloud,
			mdiCrop,
			mdiAccount,
			mdiCogOutline,
			mdiEmail,
			mdiLogout,
			mdiGithub,
		};
	},

	data() {
		return {
      userActive: this.$store.state.user.edit.isActive,
			locales: ["ua", "ru", "en", "de"],
		};
	},

  computed: {
    userIsActive() {
      return this.userActive;
    },

    checkedValue: {
      get() {
        return this.userActive
      },
      set(newValue) {
        this.userActive = newValue;
        let path = this.$router.currentRoute.path;
        this.$store.dispatch("user/setUserAsActive", {
          id: this.$store.state.auth.user.id,
          isActive: this.userActive,
        }, path);
      }
    }
  },

	mounted() {
    if(this.$store.state.auth && this.$store.state.auth.user) {
      this.$store.dispatch("user/getUser", this.$store.state.auth.user.id).then(() => {
        this.userActive = this.$store.state.user.edit.isActive;
      })
    }
  },

	methods: {
		switchLocale(locale) {
			if (this.$i18n.locale !== locale) {
				localStorage.setItem("locale", locale);
				this.$router.go(0);
			}
		},

		logOut() {
			this.$store.dispatch("auth/logout", this.form).then(
				() => {
					this.$router.push("/login");
				},
				(error) => {
					this.loading = false;
					this.message =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>
