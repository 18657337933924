import {mdiAccountMultiple, mdiFileDocument, mdiCog, mdiAlarm, mdiForum, mdiHistory} from "@mdi/js";

import i18n from "./lang";
import config from "./config";

export default [
	i18n.global.t("panel.common.menu"),
	[
		{
			to: "/rooms",
			label: i18n.global.t("panel.common.activeRooms"),
			icon: mdiForum,
			updateMark: true,
		},
		{
			to: "/booking_rooms",
			label: i18n.global.t("panel.common.bookingRooms"),
			icon: mdiAlarm,
			updateMark: true,
			hide: config.hideBooking
		},
		{
			to: "/history",
			label: i18n.global.t("panel.common.history"),
			icon: mdiHistory,
			updateMark: true,
		},
		{
			to: "/users",
			label: i18n.global.t("panel.common.users"),
			icon: mdiAccountMultiple,
			updateMark: true,
		},
		{
			to: "/products",
			label: !config.hideProduct ? i18n.global.t("panel.common.products") : i18n.global.t("panel.common.documents"),
			icon: mdiFileDocument,
			updateMark: true,
			hide: config.hideProductAndDocuments
		},
		{
			to: "/settings",
			label: i18n.global.t("panel.settings.title"),
			icon: mdiCog,
			updateMark: true,
		},
	],
];
